<script>
import { Bar } from 'vue-chartjs';

export default {
  name: "SalesChar",
  extends: Bar,
  props: {
    salesData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.salesData, this.options)
  }
};
</script>

<style scoped lang="scss">
</style>
