<template>
  <v-container
    id="container"
    class="pt-0"
    fluid>
    <h4 class="page-header">
      {{
        $t("views.dashboard.pools_selling", {
          twoDaysAgo: server.sales.counts.twoDaysAgo,
          yesterday: server.sales.counts.yesterday,
          today: server.sales.counts.today,
        })
      }}
    </h4>
    <v-card
      :loading="server.sales.loading">
      <v-card-text>
        <!-- Sales chart -->
        <v-row>
          <v-col
            lg="6"
            md="12">
            <v-card id="daily-sale-wrapper">
              <v-card-subtitle
                class="header text-center">
                {{ $t("views.dashboard.daily_sales_graph_title") }}
              </v-card-subtitle>
              <pool-sales-chart
                v-if="!server.sales.loading"
                id="sales-chart-container"
                :sales-data="server.sales.data"
                :options="salesChartOptions" />
            </v-card>
          </v-col>
        </v-row>
        <!-- Round monitor -->
        <v-card-title
          v-if="$store.getters.superuser">
          {{ $t("views.dashboard.title") }}
        </v-card-title>
        <v-row
          v-if="$store.getters.superuser">
          <v-col>
            <v-snackbar
              v-model="showSnackbar"
              :color="snackbarColor"
              top>
              {{ snackbarText }}
            </v-snackbar>
            <v-data-table
              :headers="headers"
              :items="pools"
              @click:row="getRounds">
              <template #item.collection="{item}">
                <v-btn
                  color="primary"
                  :loading="item.loading"
                  :disabled="item.loading"
                  @click.stop="passJackpot(item)">
                  Jackpot
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- Single pool rounds chart -->
        <v-row>
          <v-col
            cols="6"
            offset="3">
            <bar-char
              v-if="rounds"
              :height="200"
              :chart-data="chartData" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BarChar from "@/components/layout/BarChar";
import { emptyJackpot } from "@/api/jackpot.js";
import { getDashboard } from "@/api/dashboard.js";
import SalesChar from "@/components/dashboard/SalesChar.vue";

export default {
  name: "Dashboard",
  components: {
    BarChar,
    PoolSalesChart: SalesChar,
  },
  data: function() {
    return {
      pools: [],
      rounds: null,
      showSnackbar: false,
      snackbarText: "",
      snackbarColor: "Red",
      salesChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "top",
        },
        title: "something",
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      server: {
        sales: {
          counts: {
            twoDaysAgo: 0,
            yesterday: 0,
            today: 0,
          },
          data: {
            labels: ["A", "B"],
            datasets: [],
          },
          loading: true,
        },
      },
    };
  },
  computed: {
    chartData() {
      if (this.rounds) {
        const labels = [];
        const data = [];
        
        this.rounds.forEach(round => {
          labels.push(`${this.$t("views.tickets.monitor.round")} ${round.id}`);
          data.push(round.roulette_balance);
        });
        
        return {
          labels: labels,
          datasets: [{
            label: this.$t("views.dashboard.tableColumns.rouletteAmount"),
            data: data,
            backgroundColor: "#ade78f",
          }],
        };
      } else {
        return {};
      }
    },
    headers() {
      return [
        {
          text: this.$t("views.dashboard.tableColumns.bettingPool"),
          value: "betting_pool_name",
          align: "center",
        },
        {
          text: this.$t("views.dashboard.tableColumns.roundId"),
          value: "last_round_id",
          align: "center",
        },
        {
          text: this.$t("views.dashboard.tableColumns.amountPlayed"),
          value: "total_amount_played",
          align: "center",
        },
        {
          text: this.$t("views.dashboard.tableColumns.rouletteAmount"),
          value: "current_roulette_amount",
          align: "center",
        },
        {
          text: this.$t("views.dashboard.tableColumns.availableAmount"),
          value: "amount_available",
          align: "center",
        },
        {
          text: this.$t("views.dashboard.tableColumns.collection"),
          value: "collection",
          align: "center",
        },
      ];
    },
  },
  channels: {
    DashboardChannel: {
      connected() {
        console.log("connected");
      },
      rejected() {
        console.error("rejected");
      },
      received({ pools, rounds, message }) {
        console.log("message received", message);
        
        if (pools) {
          this.pools = pools.map(pool => ({ ...pool, loading: false }));
        }
        
        if (rounds) {
          this.rounds = rounds;
        }
      },
    },
  },
  mounted() {
    this.$cable.subscribe({
      channel: "DashboardChannel",
    });
  },
  beforeDestroy() {
    this.$cable.unsubscribe("DashboardChannel");
  },
  created() {
    getDashboard().
    then(response => {
      this.server.sales = {
        ...response.data.sales,
        loading: false,
      };
    });
  },
  methods: {
    getRounds(currentPool) {
      this.$cable.perform({
        channel: "DashboardChannel",
        action: "get_rounds",
        data: { betting_pool_id: currentPool.betting_pool_id },
      });
    },
    passJackpot(item) {
      item.loading = true;
      emptyJackpot(item.betting_pool_id).
      then(response => {
        item.loading = false;
        this.showSnackbar = true;
        if (response.statusText === "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Jackpot successfully passed!";
        } else {
          this.snackbarColor = "red";
          this.snackbarText = "There was an error.";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#daily-sale-wrapper {
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  padding: 1px;

  .header {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid gray;
  }
}

#sales-chart-container {
  position: relative;
  height: 100%;
}

.page-header {
  font-size: 24px;
  font-weight: 500;
}
</style>
