<script>
import { Bar } from "vue-chartjs";
import { reactiveProp } from "vue-chartjs/es/mixins";

export default {
  name: "BarChar",
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style scoped>

</style>
